const PENCIL = require('../static/icons/SVG/006-pencil.svg');
const FOLDER = require('../static/icons/SVG/048-folder.svg');
const PLUS = require('../static/icons/SVG/267-plus.svg')
const CHECKMARK = require('../static/icons/SVG/273-checkmark.svg');
const CANCEL = require('../static/icons/SVG/270-cancel-circle.svg');
const CIRCLE_RIGHT = require('../static/icons/SVG/323-circle-right.svg');
const BIN = require('../static/icons/SVG/173-bin.svg');
const LOOP = require('../static/icons/SVG/303-loop2.svg');
const CROSS = require('../static/icons/SVG/272-cross.svg');
const IMAGE = require('../static/icons/SVG/014-image.svg');
const FILE_PICTURE = require('../static/icons/SVG/040-file-picture.svg');
const FILE_VIDEO = require('../static/icons/SVG/043-file-video.svg');
const FILE_TEXT = require('../static/icons/SVG/039-file-text2.svg');
const FILE_EMPTY = require('../static/icons/SVG/037-file-empty.svg');
const INFO = require('../static/icons/SVG/269-info.svg');
const CLIPBOARD = require('../static/icons/SVG/185-clipboard.svg');

const ICONS = {
    pencil: PENCIL,
    folder: FOLDER,
    plus: PLUS,
    checkmark: CHECKMARK,
    cancel_circle: CANCEL,
    circle_right: CIRCLE_RIGHT,
    bin: BIN,
    loop: LOOP,
    cross: CROSS,
    image: IMAGE,
    file_picture: FILE_PICTURE,
    file_video: FILE_VIDEO,
    file_text: FILE_TEXT,
    file_empty: FILE_EMPTY,
    info: INFO,
    clipboard: CLIPBOARD,
}

export { ICONS }