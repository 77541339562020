<template lang="pug">
    base-layout
        template(slot="main")
            registration-form
</template>

<script>
//Components
import BaseLayout from '@/layout/base-layout';
import RegistrationForm from '@/components/views/registration'

export default {
    name: "registration",
    components: {
        'registration-form': RegistrationForm,
        'base-layout': BaseLayout,
    }
}
</script>