<template lang="pug">
    .flex.flex-col.gap-5.w-300.p-4
        .flex.flex-col
            label(for="name") {{FORM.name}}
            input.border-1.h-10(type="text" id="name" v-model="form.name")
        .flex.flex-col
            label(for="lastname") {{FORM.lastname}}
            input.border-1.h-10(type="text" id="lastname" v-model="form.lastname")
        .flex.flex-col
            label(for="phone") {{FORM.phone}}
            input.border-1.h-10(type="text" id="phone" v-model="form.phone" v-mask="'+7(###)###-##-##'")
        .flex.flex-col
            label(for="email") {{FORM.email}}
            input.border-1.h-10(type="text" id="email" v-model="form.email")
        .flex.flex-col
            label(for="password") {{FORM.password}}
            input.border-1.h-10(type="password" id="password" v-model="form.password")
            
        app-checkbox(@value="onConditions")
            template
                .text-m.ml-2 Lorem ipsum dolor sit amet.
        
        app-checkbox(@value="onAgreement")
            template
                .text-m.ml-2 Lorem ipsum dolor sit amet.
        
        button.bg-gray-300.p-2(@click="onRegistration($data.form)") {{ isLoading ? '...' : FORM.register }}
</template>

<script>
//Components
import checkbox from '@/components/common/checkbox';
//Store
import { RegistrationNameSpace, RegistrationActionTypes } from '@/store/registration/types';
//Config
import { FORM } from './config'

export default {
    name: "registration-form",
    components: {
        'app-checkbox': checkbox,
    },
    data() {
        return {
            form: {
                name: '',
                lastname: '',
                email: '',
                password: '',
                personal_data_conditions: false,
                user_agreement: false,
            },
            isLoading: false,
        }
    },
    computed: {
        FORM() {
            return FORM
        },
    },
    methods: {
        onConditions(value) {
            this.form.personal_data_conditions = value
        },
        onAgreement(value) {
            this.form.user_agreement = value
        },
        async onRegistration(form_data) {            
            try {
                this.isLoading = true
                await this.$store.dispatch(`${RegistrationNameSpace}/${RegistrationActionTypes.Register}`, { data: {...form_data} })
                localStorage.setItem('email', this.form.email)
                this.$router.push('/login')
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }            
        },
    }
}
</script>