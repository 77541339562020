const FORM = {
    name: 'Имя',
    lastname: 'Фамилия',
    phone: 'Номер телефона',
    email: 'Электронная почта',
    password: 'Пароль',
    repeat_password: 'Пароль еще раз',
    conditions: 'Я принимаю условия обработки персональных данных',
    agreement: 'Я принимаю пользовательское соглашение',
    register: 'Зарегистрироваться',
}

export { FORM }