<template lang="pug">
    .flex.items-top
        .w-5.h-5.border.flex.items-center.justify-center.cursor-pointer(@click="onCheck")
            template(v-if="isChecked")
                img.block(:src="ICONS.checkmark")
        template(v-if="$slots.default")
            slot
</template>

<script>
//Configs
import { ICONS } from '../../../configs/icons';

export default {
    name: "checkbox",
    data() {
        return {
            isChecked: false,
        }
    },
    computed: {
        ICONS() {
            return ICONS
        },
    },
    methods: {
        onCheck() {
            this.isChecked = !this.isChecked
            this.$emit('value', this.isChecked)
        },
    },
}
</script>